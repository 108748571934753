import { projectAuth, authPersistence } from "../firebase/config";
import { ref } from "vue";

export default () => {
  const error = ref(null);
  const pending = ref(null);

  const login = async ({ email, password, remember }) => {
    let storage;
    switch (remember) {
      case true:
        storage = "LOCAL";
        break;
      default:
        storage = "SESSION";
    }
    try {
      pending.value = true;
      await projectAuth.setPersistence(authPersistence[storage]);
      await projectAuth.signInWithEmailAndPassword(email, password);
      // const userToken = await projectAuth.currentUser.getIdTokenResult();
      // if (!userToken.claims.roles.admin) {
      //   error.value = { message: "Accès non autorisé" };
      //   return await projectAuth.signOut();
      // }
      error.value = null;
    } catch (e) {
      error.value = { message: "Adresse email ou mot de passe incorrect" };
    } finally {
      pending.value = null;
    }
  };

  const logout = async () => {
    try {
      await projectAuth.signOut();
    } catch (e) {
      error.value = e.message;
    }
  };

  return { error, pending, login, logout };
};
